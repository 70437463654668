import Vue from 'vue'
import VueRouter from 'vue-router'
// import projectManage from '../views/projectManage/index.vue'


Vue.use(VueRouter)

const routes = [{
    path:"/",
    redirect: "/entry",
    // component:() => import(/* webpackChunkName: "about" */ '@/views/entry.vue')
  },
  {
    name:"entry",
    path:"/entry",
    // redirect: "/projectstManage/projectManage",
    component:() => import(/* webpackChunkName: "about" */ '@/views/entry.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
